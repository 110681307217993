
/* eslint-disable */

const awsmobile = {
	aws_project_region: 'us-east-1',
	aws_cognito_identity_pool_id: 'us-east-1:47ee76f7-965e-4fa3-9bb9-f1237dc0141d',
	aws_cognito_region: 'us-east-1',
	aws_user_pools_id: 'us-east-1_Jkye1YQZ7',
	aws_user_pools_web_client_id: '4aocd7nqqmqpcvoj1p2s00g109',
	oauth: {},
	debug: '1',
	aws_cloud_logic_custom: [
		{
			name: 'management',
			endpoint: 'https://dev.mdmesolutions.com/api',
			region: 'us-east-1'
		},
		{
			name: 'admin',
			endpoint: 'https://dev.mdmesolutions.com/api',
			region: 'us-east-1'
		},
		{
			name: 'external',
			endpoint: 'https://dev.mdmesolutions.com/api',
			region: 'us-east-1'
		},
		{
			name: 'files',
			endpoint: 'https://dev.mdmesolutions.com/api',
			region: 'us-east-1'
		},
		{
			name: 'consumers',
			endpoint: 'https://dev.mdmesolutions.com/api',
			region: 'us-east-1'
		}
	],
	aws_user_files_s3_bucket: 'mdme-dev-public',
	aws_user_files_s3_bucket_region: 'us-east-1'
};

export default awsmobile;
